<template>
    <v-menu v-model="isOpen" width="30%" :close-on-content-click="false" offset-x>
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-text-field ref="searchInput" v-model="search" placeholder="Nom" solo flat hide-details />

            <v-divider />

            <v-list-item-group v-model="selectedRecipients">
                <v-list-item v-for="recipient of recipients" :value="recipient" :key="recipient.email">
                    <v-list-item-avatar class="blue white--text text-uppercase" size="32">
                        <v-img v-if="recipient.profilePictureDocumentId" :src="getDocumentUrl(recipient.profilePictureDocumentId)" />
                        <span v-else-if="recipient.name"> {{ recipient.name.slice(0, 1) }} </span>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title class="body-2">
                            {{ recipient.name }} {{ recipient.firstname }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            <span class="grey--text"> {{ recipient.job }} </span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="recipients.length === 0">
                    <v-list-item-content>
                        <v-list-item-title class="body-2">
                            Aucun résultat
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-card>
    </v-menu>
</template>

<script>
import messagesMixin from '../../mixins/messages';
import documentsMixin from '../../mixins/documents';

export default {
    name: 'CreateMessageGroupMenuUser',

    mixins: [messagesMixin, documentsMixin],

    props: {
        activeConversations: { type: Array, default: () => ([]) }
    },

    data: () => ({
        isOpen: false,
        search: '',
        selectedRecipients: []
    }),

    computed: {
        recipients() {
            return this.userMessageRecipients
                .filter((r) => {
                    return (!this.search || r.name.toLowerCase().includes(this.search))
                        && !this.activeConversations.some((c) => c.users.some((u) => u.id == r.id && u.type === r.type));
                });
        }
    },

    watch: {
        selectedRecipients() {
            this.$emit('created', { recipients: [this.selectedRecipients] });
            this.isOpen = false;
        }
    }
};
</script>
